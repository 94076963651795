import Vue from 'vue'
import { StoreOptions, GetterTree, MutationTree, ActionTree } from 'vuex'
import AdoriService from '@/services/adori'
import { whitelistedEmails } from '@/constants/env'

const state: any = {
  profile: null,
  verifiedEmails: [],
  isWhitelisted: false,
}

const getters: GetterTree<any, any> = {
  profile: (state) => state.profile,
  userId: (state) => state.profile.id,
  avatar: (state) => state.profile.profileImageId,
  name: (state) => state.profile.name,
  email: (state) => state.profile && state.profile.email,
  verifiedEmails: (state) => state.verifiedEmails,
  isWhitelisted: (state) => state.profile && whitelistedEmails.includes(state.profile.email),
}

const mutations: MutationTree<any> = {
  setProfile(state, data) {
    Vue.set(state, 'profile', data)
  },

  updateProfileInfo(state, data) {
    Vue.set(state.profile, 'name', data.name)
    Vue.set(state.profile, 'profileImageId', data.profileImageId)
    Vue.set(state.profile, 'bio', data.bio)
    if (data.profileImageUrl) {
      Vue.set(state.profile, 'profileImageInfo', { url: data.profileImageUrl })
    }
  },

  updateProfileNetworkInfo(state, info) {
    const i = state.profile.networks.member_networks.findIndex((nw: any) => nw.network.id === info.id)
    if (i !== -1) {
      Vue.set(state.profile.networks.member_networks[i], 'network', info)
    }
  },

  setVerifiedEmails(state, data) {
    Vue.set(state, 'verifiedEmails', data)
  },
}

const actions: ActionTree<any, any> = {
  async getProfile(context, networkIndex = 0) {
    let profile: any = {}

    if (context.state.profile) profile = context.state.profile
    else profile = await AdoriService.fetchProfile()

    context.commit('setProfile', profile)
    if (profile.networks.member_networks.length || profile.networks.member_shows.length) {
      context.dispatch('setNetworks', {
        networks: profile.networks,
        index: networkIndex,
      })
    }
  },

  async setProfile(context, profile) {
    context.commit('setProfile', profile)
  },

  async updateProfileInfo(context, profile) {
    context.commit('updateProfileInfo', profile)
  },

  async updateProfileNetworkInfo(context, info) {
    context.commit('updateProfileNetworkInfo', info)
  },

  async getVerifiedEmails(context) {
    const networkId = context.getters.networkId
    const emails = await AdoriService.fetchVerifiedEmails(networkId)
    context.commit('setVerifiedEmails', emails)
  },
}

const profile: StoreOptions<any> = {
  state,
  getters,
  mutations,
  actions,
}

export default profile
